.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.panel {
  background-color:floralwhite;
  border: gainsboro 1px solid;
}

.panel .panel-heading {
  cursor: pointer;
} 
.panel .panel-collapse {
    overflow: hidden;
    transition: height 0.3s ease-out;  
}
.panel  .panel-body {
    border: none !important;
}
.panel h2 {
  margin-top: 5px !important; 
  text-transform: capitalize
}


.options-margin-top {
  margin-top: 10px;
  cursor: pointer;
}

.root {
  flexGrow: 1,
}

.grow {
  flexGrow: 1,
}

.container {
  text-align: center;
  padding: 15px 5px 15px 5px;
  margin-left: 8px;
  margin-bottom: 10px;
  width: 98% !important;
  padding-top: 50px
}